// Define common colors here
import { FEATURES } from '@/config/features';
import { AppColors, getColor } from '@/helpers/utils';
import { FontAwesomeIcon } from '@/types/common/fa';

export const defaultColorMap: { [key: string]: AppColors } = {
  [FEATURES.Customer.icon]: 'customer',
  [FEATURES.Writer.icon]: 'writer',
  [FEATURES.User.icon]: 'user',
  'fa-trash-alt': 'danger',
  'fa-trash': 'danger',
  'fa-thumbs-down': 'danger',
  'fa-thumbs-up': 'success',
  'fa-play': 'success',
  'fa-pause': 'muted',
  'fa-message-slash': 'gray',
  'fa-eye-slash': 'muted-text',
  'fa-eye': 'primary',
  'fa-filter': 'info',
  'fa-times-circle': 'warning',
  [FEATURES.Order.icon]: 'purple',
  [FEATURES.Revision.icon]: 'warning',
};

export const getIconColor = (icon: FontAwesomeIcon, fallback?: string): string => fallback ?? getColor(defaultColorMap[icon]);
