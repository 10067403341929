import { SearchDictInterface } from '@/shared/components/searchEngine';
import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalPreferences } from '@/types/search';

export const defaultPreferences: LocalPreferences = {
  search: {},
  showAIChecksOnSinglePlagCheck: false,
  showPlagChecksOnSingleAICheck: false,
};

const localPreferenceSlice = createSlice({
  name: 'localPreferences',
  initialState: defaultPreferences,
  reducers: {
    setSearchPreferences: (state: LocalPreferences, { payload }: PayloadAction<SearchDictInterface>) => {
      state.search = payload;
    },
    setLocalPreferences(state: LocalPreferences, { payload }: PayloadAction<LocalPreferences>) {
      return payload;
    },
    resetPreferences: () => defaultPreferences,
    setShowAIChecksOnSinglePlagCheck(state: LocalPreferences, { payload }: PayloadAction<boolean>) {
      state.showAIChecksOnSinglePlagCheck = payload;
    },
    setShowPlagChecksOnSingleAICheck(state: LocalPreferences, { payload }: PayloadAction<boolean>) {
      state.showPlagChecksOnSingleAICheck = payload;
    },
    setActiveSidebars(state: LocalPreferences, { payload }: PayloadAction<string[]>) {
      state.activeSidebars = payload;
    },
  },
});

export const {
  setSearchPreferences,
  resetPreferences,
  setLocalPreferences,
  setShowAIChecksOnSinglePlagCheck,
  setShowPlagChecksOnSingleAICheck,
  setActiveSidebars,
} = localPreferenceSlice.actions;

export const searchPreferencesSelector = ({ localPreferences }: RootState): SearchDictInterface | undefined => localPreferences.search;

export const showAIChecksOnSinglePlagCheckSelector = ({ localPreferences }: RootState) => localPreferences.showAIChecksOnSinglePlagCheck;

export const showPlagChecksOnSingleAICheckSelector = ({ localPreferences }: RootState) => localPreferences.showPlagChecksOnSingleAICheck;

export const sidebarActiveKeysSelector = ({ localPreferences }: RootState) => localPreferences.activeSidebars || [];

export default localPreferenceSlice.reducer;
