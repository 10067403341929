import { FA } from '@/components/common/icons/FA';
import { DATE_FORMAT_DEFAULT } from '@/config/constants';
import { getColor } from '@/helpers/utils';
import { getTimezoneName } from '@/hooks/clock';
import { usePreferences } from '@/hooks/preferences';
import { Tooltip } from 'antd';
import cn from 'classnames';
import moment from 'moment-timezone';
import s from './DateTime.module.less';
import { useRoles } from '@/components/guards/RBAC/useRoles';
import { PropsWithChildren } from 'react';

export type DateTimeProps = PropsWithChildren<{
  className?: string;
  children?: any;
  value: string | undefined; // datetime value
  showTooltip?: boolean;
  /** @deprecated tooltipValue */
  tooltipValue?: string;
  icon?: boolean;
  prefix?: string; // works only with icon?
  customerTimezone?: boolean; // render by customer tz
  customFormat?: string;
  fromNow?: boolean;
  canOverdue?: boolean;
  targetTimezone?: string;
}>;

export const DateTime = ({
  className,
  value,
  customFormat,
  fromNow,
  icon,
  prefix,
  customerTimezone,
  canOverdue,
  showTooltip = true,
  targetTimezone,
}: DateTimeProps) => {
  const { timezone } = usePreferences();

  const { isSuperAdmin } = useRoles();

  const localTimezone = moment.tz.guess();
  const isCustom = customerTimezone && timezone !== localTimezone;
  const format = customFormat || DATE_FORMAT_DEFAULT; // z -> time shift

  const dateUTC = moment.utc(value);
  const preferredDate = moment.utc(value).tz(timezone);
  const alternativeDate = moment.utc(value).tz(localTimezone); // inverted for tooltip
  const isExpired = canOverdue && dateUTC.isBefore(moment.utc());

  const children = (
    <time className={cn(s.wrapper, className, { [s.expired]: canOverdue && isExpired })}>
      {icon && prefix && <FA icon="fa-calendar" text={prefix} />}
      {!icon && prefix && <>{prefix}</>}
      {fromNow ? (
        <span className={cn(s.fromNow, { [s.expired]: isExpired })}>{preferredDate.fromNow()}</span>
      ) : (
        preferredDate.format(format)
      )}
    </time>
  );

  if (!value) return <>n\a</>;

  if (!showTooltip) return children;

  const showUTCTime = isSuperAdmin;

  // use case for displaying date in customer timezone if given
  const showTimeInCustomTimezone = !isSuperAdmin && !!targetTimezone;

  return (
    <Tooltip
      overlayInnerStyle={{ width: 300 }}
      title={
        <time>
          <strong style={{ color: getColor('warning') }}>
            {preferredDate.format(format)} / {getTimezoneName(String(preferredDate.tz()))}
          </strong>
          <hr />
          <div>
            {alternativeDate.format(`${DATE_FORMAT_DEFAULT} ${isCustom ? 'z' : ''}`)} / {getTimezoneName(String(alternativeDate.tz()))}
          </div>

          {showUTCTime && (
            <>
              <hr />
              <div>{moment(value).utc().format(DATE_FORMAT_DEFAULT)} / UTC</div>
            </>
          )}
          {showTimeInCustomTimezone && (
            <>
              <hr />
              <div>
                {moment.utc(value).tz(targetTimezone).format(DATE_FORMAT_DEFAULT)} / {targetTimezone}
              </div>
            </>
          )}
          {isExpired && (
            <>
              <hr />
              <FA icon="fa-exclamation-circle" theme="fas" text="Date expired" /> {dateUTC.fromNow()}
            </>
          )}
        </time>
      }
    >
      {children}
    </Tooltip>
  );
};
