import { FA } from '@/components/common/icons/FA';
import { Nowrap } from '@/shared/components/layout/Nowrap';
import { Tooltip, TooltipProps } from 'antd';
import { ReactNode } from 'react';

type InfoTooltipProps = {
  text: ReactNode | string | undefined;
} & Omit<TooltipProps, 'text'>;

export const InfoTooltip: FC<InfoTooltipProps> = ({ text, children, ...props }) =>
  text ? (
    <Tooltip title={text} {...props}>
      <Nowrap>
        {children}{' '}
        <sup className="opacity-5">
          <FA className="icon-hl" icon="fa-question-circle" theme="far" color="var(--black-color)" />
        </sup>
      </Nowrap>
    </Tooltip>
  ) : null;
