import { useLazyViewCurrentUserProfileQuery, useUpdateProfilePreferencesMutation, useViewCurrentUserProfileQuery } from '@/api/profile';
import { OrderViewPreferences, UserPreferences } from '@/types/profile';
import { omit } from 'lodash';
import { useAuth } from '@/store/auth/authHooks';

const DEFAULT_PREFERENCES: UserPreferences & { timezone: string; orderSearchView: OrderViewPreferences } = {
  timezone: 'EST5EDT',
  isExtraControls: true,
  idPinnedTicket: 0,
  orderSearchView: {
    order: 'paid',
    customer: 'paid',
  },
};

// legacy key from search preferences
const BLACKLISTED_KEYS = ['search'];

const sanitizePreferences = (preferences?: UserPreferences) =>
  omit(Object.fromEntries(Object.entries(preferences || {}).filter(([_, v]) => (typeof v == 'boolean' ? true : !!v))), BLACKLISTED_KEYS);

export const usePreferences = () => {
  const { isLoggedIn } = useAuth();
  const [patchPreferences] = useUpdateProfilePreferencesMutation();
  const { data } = useViewCurrentUserProfileQuery(undefined, { skip: !isLoggedIn });
  const [getProfile] = useLazyViewCurrentUserProfileQuery();
  const updatePreferences = async (args?: Partial<UserPreferences>) => {
    // get latest preferences
    const profile = await getProfile();
    if (profile.data) {
      const sanitizedPreferences = sanitizePreferences(profile.data.preferences);
      await patchPreferences({
        preferences: {
          ...sanitizedPreferences,
          ...(args || {}),
        },
      });
    }
  };

  const preferences = {
    ...DEFAULT_PREFERENCES,
    ...sanitizePreferences(data?.preferences),
  };

  return {
    timezone: preferences.timezone,
    preferences,
    updatePreferences,
  };
};
