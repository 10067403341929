/*
 * FontAwesomeIcon
 * Types: https://fontawesome.com/v5.15/icons?d=gallery
 */

import { getIconColor } from '@/config/colors';
import type { FontAwesomeIcon, FontAwesomeThemes } from '@/types/common/fa';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

// Styles
import s from './FA.module.less';

export interface FontAwesomeIconProps {
  className?: string;
  theme?: FontAwesomeThemes;
  icon: FontAwesomeIcon;
  color?: string;
  text?: JSX.Element | string | number;
  prefix?: JSX.Element | string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  tooltip?: ReactNode | string;
  style?: CSSProperties;
}

export const FA: FC<FontAwesomeIconProps> = ({ className = '', theme = 'fal', icon, color, onClick, style, ...props }) => {
  const iconClassName: string = cn(s.ico, theme, icon, 'icon');
  return (
    <Tooltip className={s.tooltip} title={props.tooltip}>
      <span className={cn(s.wrapper, className, { [s.clickable]: !!onClick })} style={style} onClick={onClick}>
        {props.prefix} <i style={{ color: style?.color ?? getIconColor(icon, color) }} className={cn(s.ico, iconClassName)} />
        {!!props.text && <span className={s.text}>{props.text}</span>}
      </span>
    </Tooltip>
  );
};
