import { HR_FLOW_FEATURE_LIST, HR_FLOW_FEATURES } from '@/addons/hrFlow/config/features';
import { MS_FEATURE_LIST, MS_FEATURES } from '@/addons/mailService/config/features';
import { S_FEATURE_LIST, S_FEATURES } from '@/addons/scheduler/config/features';
import { RG_ADMINS, RG_S_SENIOR } from '@/components/guards/RBAC/groups';
import { FontAwesomeIcon } from '@/types/common/fa';
import { Roles } from '@/types/user';

export const FEATURE_CATEGORIES = [
  'feature',
  'writer',
  'customer',
  'support',
  'site',
  'order',
  'settings',
  'custom',
  'files',
  'finances',
  'core',
  'hidden',
  'admin',
  'hr',
  'extraFeatures',
] as const;

export type AIOrPlagCheck = 'AICheck' | 'PlagCheck';
export type AIOrPlagCheckItem = 'AICheckItem' | 'PlagCheckItem';

export type FeatureCategory = typeof FEATURE_CATEGORIES[number];
// *** Entity map ***

export type FeatureItem = {
  title: string;
  menuTitle?: string;
  path: `/${string}`;
  apiPath?: string;
  icon: FontAwesomeIcon;
  type: FeatureType; // used in RTKQ/API as invalidated/provided tagTypes
  category?: FeatureCategory;
  roles?: Roles[];
};
type FeaturesDictionary = {
  [key in FeatureType]: FeatureItem;
};

// *** Update this array before! ***
export type FeatureType = typeof FEATURE_LIST[number];
export const FEATURE_LIST = [
  'Overview',
  'Todos',
  'Dicts',
  'Login',
  'Profile',
  'Email',
  'Phone',
  'AcademicDiscipline',
  'AcademicLevel',
  'FieldOfStudy',
  'Discount',
  'ExtraFeature',
  'WorkType',
  'PaperUnit',
  'SiteSetting',
  'Site',
  'PaperType',
  'RevisionReason',
  'RevisionMagnitude',
  'GradingReason',
  'Grading',
  'User',
  'LineSpacing',
  'Customer',
  'Writer',
  'PriceQuote',
  'MerchantAccount',
  'Acquirer',
  'Fee',
  'LegalEntity',
  'InvoiceTemplate',
  'IncomingTransaction',
  'OutgoingTransaction',
  'Order',
  'WriterOrder',
  'Chargeback',
  'Refund',
  'Paycore',
  'Revision',

  // Files
  'OwnFile',
  'UserFile',
  'WriterFile',
  'CustomerFile',

  'Files',
  'Sticker',
  'CommunicationChannel',
  'WriterSkill',
  'CustomerExternalCredential',
  'CustomerMagicLink',
  'WriterMagicLink',
  'Fintech',
  'CitationStyle',
  'OrderSnapshot',
  'WriterPass',
  'WriterPayment',
  'WriterPaymentBatch',
  'WriterPayoutEmail',
  'WriterManual',
  'WriterManualTag',
  'Ticket',
  'TicketMessage',
  'FeedbackRating',
  'PlagCheck',
  'PlagCheckItem',
  'AICheck',
  'AICheckItem',
  'GradingItem',
  'WriterFieldCompetence',
  'WriterSkillCompetence',
  'Feedback',
  'WriterPassSetup',
  'BroadcastMessage',
  'GradingScore',
  'OrderDrawing',
  'OrderDrawingContender',
  'PreferredWriterSetting',
  'PreferredWriter',
  'ExtraQualityCheckSetting',
  'OriginalityReportSetting',
  'PremiumWriterSetting',
  'AIReportSetting',
  'DraftFeatureSetting',

  // Google
  'GoogleAccount',
  'GoogleApp',

  'Stats',
  'SalesPipelineSetting',
  'SalesPipeline',

  ...HR_FLOW_FEATURE_LIST,
  ...MS_FEATURE_LIST,
  ...S_FEATURE_LIST,
  'WriterPenalty',
] as const;

/*
 * Strings for Titles, Routes, API etc.
 */
export const FEATURES: FeaturesDictionary = {
  // Misc
  Overview: {
    type: 'Overview',
    title: 'Overview',
    path: '/',
    category: 'hidden',
    icon: 'fa-home',
  },
  Todos: {
    type: 'Todos',
    title: 'Todos',
    path: '/todos/*', // has nesting routes
    category: 'hidden',
    icon: 'fa-check-double',
  },
  Login: {
    type: 'Login',
    title: 'Login',
    path: '/login',
    icon: 'fa-key',
  },
  Profile: {
    type: 'Profile',
    title: 'My profile',
    path: '/profile',
    icon: 'fa-cog',
    category: 'support',
  },

  // Service entities
  Dicts: {
    title: 'Dictionaries',
    type: 'Dicts',
    path: '/dicts',
    icon: 'fa-books',
  },

  // Main entities
  FieldOfStudy: {
    type: 'FieldOfStudy',
    title: 'Fields of study',
    path: '/fields-of-study',
    category: 'settings',
    icon: 'fa-university',
  },
  Email: {
    type: 'Email',
    title: 'Emails',
    path: '/emails',
    icon: 'fa-envelope',
    category: 'core',
  },
  Phone: {
    type: 'Phone',
    title: 'Phones',
    path: '/phones',
    icon: 'fa-phone',
    category: 'core',
  },
  AcademicDiscipline: {
    type: 'AcademicDiscipline',
    title: 'Academic disciplines',
    path: '/academic-disciplines',
    icon: 'fa-graduation-cap',
    category: 'settings',
  },
  AcademicLevel: {
    type: 'AcademicLevel',
    title: 'Academic levels',
    path: '/academic-levels',
    icon: 'fa-file-certificate',
    category: 'settings',
  },
  Discount: {
    type: 'Discount',
    title: 'Discounts',
    path: '/discounts',
    icon: 'fa-percent',
    category: 'order',
  },
  ExtraFeature: {
    type: 'ExtraFeature',
    title: 'Legacy extra features',
    path: '/extra-features',
    icon: 'fa-star-shooting',
    category: 'extraFeatures',
  },
  WorkType: {
    type: 'WorkType',
    title: 'Work types',
    path: '/work-types',
    icon: 'fa-clipboard-list',
    category: 'settings',
  },
  PaperUnit: {
    type: 'PaperUnit',
    title: 'Paper units',
    path: '/paper-units',
    icon: 'fa-pencil-ruler',
    category: 'settings',
  },
  SiteSetting: {
    type: 'SiteSetting',
    title: 'Site settings',
    path: '/site-settings',
    icon: 'fa-cog',
    category: 'core',
    roles: RG_ADMINS,
  },
  Site: {
    type: 'Site',
    title: 'Sites',
    path: '/sites',
    icon: 'fa-solar-system',
    category: 'core',
  },
  PaperType: {
    type: 'PaperType',
    title: 'Paper types',
    path: '/paper-types',
    icon: 'fa-scroll',
    category: 'settings',
  },
  RevisionReason: {
    type: 'RevisionReason',
    title: 'Revision reasons',
    path: '/revision-reasons',
    icon: 'fa-question-circle',
    category: 'settings',
  },
  RevisionMagnitude: {
    type: 'RevisionMagnitude',
    title: 'Revision magnitudes',
    path: '/revision-magnitudes',
    icon: 'fa-history',
    category: 'settings',
  },
  GradingReason: {
    type: 'GradingReason',
    title: 'Grading reasons',
    path: '/grading-reasons',
    icon: 'fa-question-circle',
    category: 'settings',
  },
  Grading: {
    type: 'Grading',
    title: 'Gradings',
    path: '/gradings',
    icon: 'fa-chevron-double-down',
    category: 'order',
  },
  User: {
    type: 'User',
    title: 'Users',
    path: '/users',
    icon: 'fa-user-headset',
    category: 'support',
  },
  LineSpacing: {
    type: 'LineSpacing',
    title: 'Line spacings',
    path: '/line-spacings',
    icon: 'fa-ruler-vertical',
    category: 'settings',
  },
  Customer: {
    type: 'Customer',
    title: 'Customers',
    path: '/customers',
    icon: 'fa-user-graduate',
    category: 'customer',
  },
  Writer: {
    type: 'Writer',
    path: '/writers',
    icon: 'fa-user-edit',
    title: 'Writers',
    category: 'writer',
  },
  PriceQuote: {
    type: 'PriceQuote',
    title: 'Price quotes',
    path: '/price-quotes',
    icon: 'fa-abacus',
    category: 'order',
  },
  MerchantAccount: {
    type: 'MerchantAccount',
    title: 'Merchant accounts',
    path: '/merchant-accounts',
    icon: 'fa-wallet',
    category: 'finances',
  },
  Acquirer: {
    type: 'Acquirer',
    path: '/acquirers',
    icon: 'fa-suitcase',
    title: 'Acquirers',
    category: 'finances',
    roles: RG_S_SENIOR,
  },
  Fee: {
    type: 'Fee',
    title: 'Fees',
    path: '/fees',
    icon: 'fa-envelope-open-dollar',
    category: 'finances',
  },
  LegalEntity: {
    type: 'LegalEntity',
    title: 'Legal entities',
    path: '/legal-entities',
    icon: 'fa-gavel',
    category: 'core',
    roles: [Roles.ADMIN],
  },

  InvoiceTemplate: {
    // TODO: MISSING!!!!!!!!!!!!!!!!!!
    type: 'InvoiceTemplate',
    path: '/invoice-templates',
    icon: 'fa-file-code',
    title: 'Invoice templates',
    category: 'finances',
    roles: [Roles.ADMIN],
  },
  IncomingTransaction: {
    type: 'IncomingTransaction',
    title: 'Incoming transactions',
    path: '/incoming-transactions',
    icon: 'fa-usd-circle',
    category: 'finances',
  },
  OutgoingTransaction: {
    type: 'OutgoingTransaction',
    title: 'Outgoing transactions',
    path: '/outgoing-transactions',
    icon: 'fa-usd-circle',
    category: 'finances',
  },
  Order: {
    title: 'Orders',
    type: 'Order',
    path: '/orders',
    icon: 'fa-money-check-dollar-pen',
    category: 'order',
  },
  WriterOrder: {
    type: 'WriterOrder',
    path: '/writer-orders',
    icon: 'fa-file-pen',
    title: 'Writer orders',
    category: 'order',
  },
  Chargeback: {
    type: 'Chargeback',
    path: '/chargebacks',
    icon: 'fa-money-check-alt',
    title: 'Chargebacks',
    category: 'finances',
  },
  Refund: {
    type: 'Refund',
    title: 'Refunds',
    path: '/refunds',
    icon: 'fa-money-check-alt',
    category: 'finances',
  },
  Paycore: {
    type: 'Paycore',
    title: 'Paycore',
    path: '/paycore',
    icon: 'fa-parking',
    category: 'finances',
  },
  Revision: {
    type: 'Revision',
    path: '/revisions',
    icon: 'fa-arrows-repeat',
    title: 'Revisions',
    category: 'order',
  },
  OwnFile: {
    type: 'OwnFile',
    title: 'Own files',
    path: '/own-files',
    icon: 'fa-bookmark',
    category: 'files',
  },
  UserFile: {
    type: 'UserFile',
    title: 'User files',
    path: '/user-files',
    icon: 'fa-user-headset',
    category: 'files',
  },
  WriterFile: {
    type: 'WriterFile',
    title: 'Writer files',
    path: '/writer-files',
    icon: 'fa-user-edit',
    category: 'files',
  },
  CustomerFile: {
    type: 'CustomerFile',
    title: 'Customer files',
    path: '/customer-files',
    icon: 'fa-user-graduate',
    category: 'files',
  },
  Files: {
    type: 'Files',
    title: 'Files',
    path: '/files',
    icon: 'fa-files',
    category: 'custom',
  },
  Sticker: {
    type: 'Sticker',
    title: 'Stickers',
    path: '/stickers',
    icon: 'fa-note-sticky',
  },
  CommunicationChannel: {
    type: 'CommunicationChannel',
    title: 'Communication channels',
    path: '/communication-channels',
    icon: 'fa-walkie-talkie',
    category: 'core',
  },
  WriterSkill: {
    type: 'WriterSkill',
    title: 'Writer skill',
    path: '/writer-skills',
    icon: 'fa-chart-pie',
    category: 'writer',
  },
  CustomerExternalCredential: {
    type: 'CustomerExternalCredential',
    title: 'Login details',
    path: '/customer-external-credentials',
    icon: 'fa-user-lock',
    category: 'customer',
  },
  CustomerMagicLink: {
    type: 'CustomerMagicLink',
    title: 'Customer magic link',
    path: '/customer-magic-links',
    icon: 'fa-user-lock',
    category: 'hidden',
    // roles: [Roles.ADMIN, Roles.SEMI_ADMIN, Roles.CS_SUPERVISOR],
  },
  WriterMagicLink: {
    type: 'WriterMagicLink',
    title: 'Writer magic link',
    path: '/writer-magic-links',
    icon: 'fa-user-lock',
    category: 'hidden',
    // roles: [Roles.ADMIN, Roles.SEMI_ADMIN, Roles.CS_SUPERVISOR],
  },
  Fintech: {
    type: 'Fintech',
    title: 'Fintechs',
    path: '/fintechs',
    icon: 'fa-landmark',
    category: 'finances',
  },
  CitationStyle: {
    type: 'CitationStyle',
    title: 'Citation styles',
    path: '/citation-styles',
    icon: 'fa-book-font',
    category: 'settings',
  },
  OrderSnapshot: {
    type: 'OrderSnapshot',
    title: 'Order snapshots',
    menuTitle: 'Snapshots',
    path: '/order-snapshots',
    icon: 'fa-aperture',
    category: 'order',
  },
  WriterPass: {
    type: 'WriterPass',
    title: 'Writer passes',
    path: '/writer-passes',
    icon: 'fa-file-certificate',
    category: 'writer',
  },
  WriterPayment: {
    type: 'WriterPayment',
    title: 'Writer payments',
    path: '/writer-payments',
    icon: 'fa-file-invoice-dollar',
    category: 'finances',
  },
  WriterPaymentBatch: {
    type: 'WriterPaymentBatch',
    title: 'Writer payment batches',
    path: '/writer-payment-batches',
    icon: 'fa-layer-group-plus',
    category: 'finances',
  },
  WriterPayoutEmail: {
    type: 'WriterPayoutEmail',
    title: 'Writer payout emails',
    path: '/writer-payout-emails',
    icon: 'fa-envelopes-bulk',
    category: 'finances',
  },
  WriterManual: {
    type: 'WriterManual',
    title: 'Writer manual',
    path: '/writer-manuals',
    icon: 'fa-square-question',
    category: 'writer',
  },
  WriterManualTag: {
    type: 'WriterManualTag',
    title: 'Writer manual tag',
    path: '/writer-manual-tags',
    icon: 'fa-tags',
    category: 'writer',
  },
  Ticket: {
    type: 'Ticket',
    title: 'Tickets',
    path: '/tickets',
    icon: 'fa-ticket',
    category: 'feature',
  },

  // Messengers
  TicketMessage: {
    type: 'TicketMessage',
    title: 'Ticket messages',
    path: '/ticket-messages',
    icon: 'fa-messages',
    category: 'hidden',
  },
  FeedbackRating: {
    type: 'FeedbackRating',
    title: 'Feedback ratings',
    path: '/feedback-ratings',
    icon: 'fa-star',
    category: 'settings',
  },
  PlagCheck: {
    type: 'PlagCheck',
    title: 'Plagiarism check',
    path: '/plag-checks',
    icon: 'fa-parking-circle',
    category: 'feature',
    // roles: [], -> ANY
  },
  PlagCheckItem: {
    type: 'PlagCheckItem',
    title: 'Plag check item',
    path: '/plag-check-items',
    icon: 'fa-file-powerpoint',
    category: 'hidden',
    // roles: [], -> ANY
  },
  AICheck: {
    type: 'AICheck',
    title: 'AI check',
    path: '/ai-checks',
    icon: 'fa-head-side-brain',
    category: 'feature',
    // roles: [], -> ANY
  },
  AICheckItem: {
    type: 'AICheckItem',
    title: 'AI check item',
    path: '/ai-check-items',
    icon: 'fa-file-powerpoint',
    category: 'hidden',
    // roles: [], -> ANY
  },
  GradingItem: {
    type: 'GradingItem',
    title: 'Grading item',
    path: '/grading-items',
    icon: 'fa-file-powerpoint',
    category: 'hidden',
  },
  WriterFieldCompetence: {
    type: 'WriterFieldCompetence',
    title: 'Writer field competences',
    path: '/writer-field-competences',
    icon: 'fa-book-user',
    category: 'writer',
  },
  WriterSkillCompetence: {
    type: 'WriterSkillCompetence',
    title: 'Writer skill competences',
    path: '/writer-skill-competences',
    icon: 'fa-book-spells',
    category: 'writer',
  },
  Feedback: {
    type: 'Feedback',
    title: 'Feedbacks',
    path: '/feedbacks',
    icon: 'fa-comment-dots',
    category: 'custom',
  },
  WriterPassSetup: {
    type: 'WriterPassSetup',
    path: '/writer-pass-setups',
    icon: 'fa-certificate',
    title: 'Writer pass setups',
    category: 'writer',
  },
  BroadcastMessage: {
    type: 'BroadcastMessage',
    path: '/broadcast-messages',
    icon: 'fa-tower-broadcast',
    title: 'Broadcast messages',
    category: 'custom',
  },
  GradingScore: {
    type: 'GradingScore',
    path: '/grading-scores',
    icon: 'fa-check-circle',
    title: 'Grading scores',
    category: 'custom',
  },
  OrderDrawing: {
    type: 'OrderDrawing',
    path: '/order-drawings',
    icon: 'fa-pen-swirl',
    title: 'Order drawings',
    category: 'order',
  },
  OrderDrawingContender: {
    type: 'OrderDrawingContender',
    path: '/order-drawing-contenders',
    icon: 'fa-user-ninja',
    title: 'Order drawing contenders',
    category: 'order',
  },
  PreferredWriterSetting: {
    type: 'PreferredWriterSetting',
    path: '/preferred-writer-settings',
    icon: 'fa-square-pen',
    title: 'Preferred writer settings',
    category: 'extraFeatures',
  },
  PreferredWriter: {
    type: 'PreferredWriter',
    path: '/preferred-writers',
    icon: 'fa-user-edit',
    title: 'Preferred writers',
    category: 'extraFeatures',
  },
  ExtraQualityCheckSetting: {
    type: 'ExtraQualityCheckSetting',
    path: '/extra-quality-check-settings',
    icon: 'fa-square-star',
    title: 'Extra quality check settings',
    category: 'extraFeatures',
  },
  OriginalityReportSetting: {
    type: 'OriginalityReportSetting',
    path: '/originality-report-settings',
    icon: 'fa-copyright',
    title: 'Originality report settings',
    category: 'extraFeatures',
  },
  PremiumWriterSetting: {
    type: 'PremiumWriterSetting',
    path: '/premium-writer-settings',
    icon: 'fa-diamond',
    title: 'Premium writer settings',
    category: 'extraFeatures',
  },
  AIReportSetting: {
    type: 'AIReportSetting',
    path: '/ai-report-settings',
    icon: 'fa-brain-circuit',
    title: 'AI report settings',
    category: 'extraFeatures',
  },
  DraftFeatureSetting: {
    type: 'DraftFeatureSetting',
    path: '/draft-feature-settings',
    icon: 'fa-scroll-old',
    title: 'Draft feature settings',
    category: 'extraFeatures',
  },

  // Google
  GoogleAccount: {
    type: 'GoogleAccount',
    path: '/google-accounts',
    icon: 'fa-user-check',
    title: 'Google accounts',
    category: 'admin',
  },
  GoogleApp: {
    type: 'GoogleApp',
    path: '/google-apps',
    icon: 'fa-code',
    title: 'Google apps',
    category: 'admin',
  },
  Stats: {
    type: 'Stats',
    path: '/stats',
    icon: 'fa-chart-line',
    title: 'Statistics',
    category: 'finances',
  },
  SalesPipelineSetting: {
    type: 'SalesPipelineSetting',
    path: '/sales-pipeline-settings',
    icon: 'fa-timeline',
    title: 'Sales pipeline settings',
    category: 'settings',
  },
  SalesPipeline: {
    type: 'SalesPipeline',
    path: '/sales-pipelines',
    icon: 'fa-chart-user',
    title: 'Sales pipelines',
    category: 'order',
  },
  ...HR_FLOW_FEATURES,
  ...MS_FEATURES,
  ...S_FEATURES,
  WriterPenalty: {
    type: 'WriterPenalty',
    path: '/writer-penalties',
    icon: 'fa-file-minus',
    title: 'Writer penalties',
    category: 'writer',
  },
};

export const getURL = (feature: FeatureType, suffix?: string | number): string => {
  const _feature = FEATURES[feature];
  if (!_feature) return 'n\\a';
  const { path } = _feature;
  return `${path.replaceAll('/*', '')}${suffix ? `/${suffix}` : ''}`;
};
