import { useEffect, useRef } from 'react';
import {
  WSAnyMessage,
  WSEventTypeIds,
  WSMessageEventDetail,
  WSSalesPipelineData,
  WSTicketData,
  WSTicketMessageData,
} from '@/shared/features/websockets/types/websockets';
import { askNotificationPermission } from '@/hooks/notifications/notifications';
import { MESSENGER_CONFIG } from '@/config/messenger';
import { useAuth } from '@/store/auth/authHooks';
import { usePreferences } from '@/hooks/preferences';
import { DEFAULT_NOTIFICATION_SOUND, TAB_NOTIFICATION_KEYS } from '@/config/constants';
import { useInvalidateTags } from '@/store/emptyApi';

const notificationExistsInStorage = (idEvent: number, idNotification: number) => {
  const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);

  if (!allKeys) return false;

  try {
    const parsed = JSON.parse(allKeys);

    return !!parsed.find((item) => item.idEvent === idEvent && item.idNotification === idNotification);
  } catch (err) {
    localStorage.removeItem(TAB_NOTIFICATION_KEYS);
    return false;
  }
};

const addNotificationToStorage = (idEvent: number, idNotification: number) => {
  const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);

  let updatedValue = JSON.stringify([
    {
      idEvent,
      idNotification,
    },
  ]);

  try {
    if (allKeys) {
      const parsed = JSON.parse(allKeys);

      if (Array.isArray(allKeys)) {
        const targetNotification = !!parsed.find((item) => item.idEvent === idEvent && item.idNotification === idNotification);

        if (!targetNotification) {
          parsed.push({
            idEvent,
            idNotification,
          });

          updatedValue = JSON.stringify(parsed);
        }
      }
    }
  } catch (err) {}

  localStorage.setItem(TAB_NOTIFICATION_KEYS, updatedValue);
};

export const useMessageNotifications = () => {
  const { user } = useAuth();

  const { preferences } = usePreferences();

  const refetchPipelines = useInvalidateTags(['SalesPipeline'], false);
  const refetchOrders = useInvalidateTags(['Order'], false);

  const prefs = preferences?.notificationSoundPreferences;

  const prefsRef = useRef(prefs);
  prefsRef.current = prefs;
  const userIdRef = useRef(user?.id);
  userIdRef.current = user?.id;

  useEffect(() => {
    const handleBroadcastNotification = async (event: CustomEvent<WSMessageEventDetail>, idEvent: number, idNotification: number) => {
      addNotificationToStorage(idEvent, idNotification);
      let title = '';
      const image = `https://${window.location.host}/img/favicon.png`;
      let body = '';
      let url = '';
      let sound = '';

      console.log('handleBroadcastNotification launched!!!!!!!!!!!!!');

      try {
        const eventData: WSAnyMessage = JSON.parse(event.detail.data.data as string);

        const { idEventType } = eventData.data;

        if ([WSEventTypeIds.TicketMessageCreatedByWriter, WSEventTypeIds.TicketMessageCreatedByCustomer].includes(idEventType)) {
          const data = eventData.data as WSTicketMessageData;

          // ignore tickets that are being updated by current user
          if (data?.author?.id === userIdRef.current) return;

          title = 'New message received.';
          body = 'Check message';
          url = `/tickets/${data.idTicket}?tab=messenger`;

          if (idEventType === WSEventTypeIds.TicketMessageCreatedByWriter) {
            sound = prefsRef.current?.ticketMessageReceivedFromWriter || '';
          }

          if (idEventType === WSEventTypeIds.TicketMessageCreatedByCustomer) {
            sound = prefsRef.current?.ticketMessageReceivedFromCustomer || '';
          }
        } else if ([WSEventTypeIds.TicketCreatedByWriter, WSEventTypeIds.TicketCreatedByCustomer].includes(idEventType)) {
          const ticketData = eventData.data as WSTicketData;
          if (ticketData?.author?.id === userIdRef.current) return;
          title = 'New ticket appeared.';
          body = 'Check ticket';
          url = '/todos/tickets?tab=pending';

          if (idEventType === WSEventTypeIds.TicketCreatedByWriter) {
            sound = prefsRef.current?.ticketCreatedByWriter || '';
          }

          if (idEventType === WSEventTypeIds.TicketCreatedByCustomer) {
            sound = prefsRef.current?.ticketCreatedByCustomer || '';
          }
        } else if (
          [WSEventTypeIds.RevisionCreated, WSEventTypeIds.RevisionCreatedByCustomer, WSEventTypeIds.RevisionCreatedByUser].includes(
            idEventType,
          )
        ) {
          const revisionData = eventData.data as WSTicketData;
          if (revisionData?.author?.id === userIdRef.current) return;
          title = 'New revision requested.';
          body = 'Check revision';
          url = '/todos/revisions?tab=claimed';
          sound = prefsRef.current?.revisionRequested || '';
        } else if ([WSEventTypeIds.OrderPaid, WSEventTypeIds.OrderPaymentReceived].includes(idEventType)) {
          const orderData = eventData.data as WSTicketData;
          if (orderData?.author?.id === userIdRef.current) return;
          title = 'New order paid.';
          body = 'Check order';
          url = '/todos/orders?tab=pending';
          sound = prefsRef.current?.orderPaid || '';
        } else if ([WSEventTypeIds.OrderToggleVerificationStatusByUser].includes(idEventType)) {
          const orderData = eventData.data as WSTicketData;

          const orderPath = `/orders/${orderData.id}`;
          if (new URL(window.location.href).pathname === orderPath) return;
          refetchOrders();
          title = 'Order verification status changed.';
          body = 'Check order';
          url = `/orders/${orderData.id}`;
          sound = prefsRef.current?.orderVerified || '';
        } else if (WSEventTypeIds.SalesPipelineStickerManipulationByUser === idEventType) {
          const pipelineData = eventData.data as WSSalesPipelineData;
          const singlePipelinePath = `/sales-pipelines/${pipelineData.id}`;
          if (new URL(window.location.href).pathname === singlePipelinePath) return;
          refetchPipelines();

          title = 'Stickers for sales pipeline changed.';
          body = 'Check pipeline';
          url = `/sales-pipelines/${pipelineData.id}`;
          sound = prefsRef.current?.salesPipelineStickerManipulated || '';
        }

        if (title) {
          // sending notification
          const notification = new Notification(title, { body, image });

          notification.onclick = (event) => {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(`https://${window.location.host}${url}`, '_blank');
          };

          const audio = new Audio(`/sound/notifications/${sound || DEFAULT_NOTIFICATION_SOUND}`);

          audio.volume = prefsRef.current?.volume || 0.5;
          audio.muted = false;

          audio.addEventListener('canplaythrough', () => {
            audio.play();
          });

          audio.addEventListener('ended', () => {
            audio.remove();
          });
        }
      } catch (err) {
        console.log('ERROR', err);
      }

      setTimeout(() => {
        const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);
        if (!allKeys) return;
        try {
          const parsed = JSON.parse(allKeys);
          localStorage.setItem(
            TAB_NOTIFICATION_KEYS,
            JSON.stringify(parsed.filter((item) => !(item.idEvent === idEvent && item.idNotification === idNotification))),
          );
        } catch (err) {}
      }, 5000);
    };

    const handleNotification = async (event: CustomEvent<WSMessageEventDetail>) => {
      // Not allowed
      if (event.detail.path !== MESSENGER_CONFIG.websockets.ticket) return;

      const notificationsAllowed = await askNotificationPermission();

      // Not allowed in browser settings or user does not agree
      if (!notificationsAllowed) return;
      // user turned off notifications in personal preferences
      if (typeof prefsRef.current?.enabled === 'boolean' && !prefsRef.current.enabled) return;

      const eventData: WSAnyMessage = JSON.parse(event.detail.data.data as string);

      setTimeout(() => {
        if (notificationExistsInStorage(eventData.event.id, eventData.data.id)) {
          // already triggered
          return;
        }

        handleBroadcastNotification(event, eventData.event.id, eventData.data.id);
      }, Math.floor(Math.random() * (500 + 1)));
    };

    document.addEventListener('wsMessageReceived', handleNotification as any);
    return () => {
      document.removeEventListener('wsMessageReceived', handleNotification as any);
    };
  }, [refetchOrders, refetchPipelines]);
};
