import {
  RG_ADMINS,
  RG_QA_AGENT,
  RG_QA_TEAM_LEAD,
  RG_S_JUNIOR,
  RG_S_MIDDLE,
  RG_S_SENIOR,
  RG_S_TEAM_LEAD,
} from '@/components/guards/RBAC/groups';
import { useIdRole } from '@/components/guards/RBAC/useIdRole';
import { Roles } from '@/types/user';

/**
 * Main hook
 * @returns dict
 */
export const useRoles = () => {
  const idRole = useIdRole();
  return {
    idRole,

    // Groups (cascades)
    isSuperAdmin: idRole === Roles.ADMIN, // only super admin
    isAdmins: RG_ADMINS.includes(idRole),

    isTeamLead: RG_S_TEAM_LEAD.includes(idRole),
    isSenior: RG_S_SENIOR.includes(idRole),
    isMiddle: RG_S_MIDDLE.includes(idRole),
    isJunior: RG_S_JUNIOR.includes(idRole),

    // QA
    isQaAgent: RG_QA_AGENT.includes(idRole),
    isQaLead: RG_QA_TEAM_LEAD.includes(idRole),

    isPaycore: idRole === Roles.PAYCORE, // no UI
  };
};

// TODO: DEBUG & TEST
