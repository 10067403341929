import { FEATURES } from '@/config/features';
import { emptyApi as api } from '@/store/emptyApi';
import { UpdateTimezoneRequest } from '@/types/common/api';
import { AddEmailRequest, AssociationEmail, EditEmailRequest } from '@/types/email';
import { AddSecondaryPhoneRequest, AssociationPhone, EditSecondaryPhoneRequest } from '@/types/phone';
import { ChangePasswordRequest, ProfileInfo, UpdatePreferencesRequest } from '@/types/profile';

// Entity
const { type, path } = FEATURES.Profile;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    viewCurrentUserProfile: build.query<ProfileInfo, void>({
      providesTags: [{ type }],
      extraOptions: { customErrorHandler: true },
      query: () => ({ url: path }),
    }),
    changeProfilePassword: build.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: `${path}/password`,
        method: 'PATCH',
        body,
      }),
    }),
    updateProfilePreferences: build.mutation<void, UpdatePreferencesRequest>({
      query: (body) => ({ url: `${path}/preferences`, method: 'PATCH', body }),
      extraOptions: { customErrorHandler: true },
      onQueryStarted: async (arg, { dispatch }) => {
        dispatch(
          injectedRtkApi.util.updateQueryData('viewCurrentUserProfile', undefined, (draft) => {
            draft.preferences = arg.preferences;
          }),
        );
      },
    }),
    setProfileTimezone: build.mutation<void, UpdateTimezoneRequest>({
      query: ({ body }) => ({ url: `${path}/timezone`, method: 'PATCH', body }),
      invalidatesTags: [{ type }],
    }),

    getProfileAPhones: build.query<AssociationPhone[], number>({
      query: () => ({ url: `${path}/a/phones` }),
      providesTags: [{ type, id: 'aPhones' }],
    }),
    claimProfileAPhone: build.mutation<AssociationPhone, AddSecondaryPhoneRequest>({
      query: ({ body }) => ({ url: `${path}/a/phones/claim`, method: 'PUT', body }),
    }),
    abandonProfileAPhone: build.mutation<AssociationPhone, EditSecondaryPhoneRequest>({
      query: ({ body }) => ({ url: `${path}/a/phones/abandon`, method: 'DELETE', body }),
      invalidatesTags: [{ type, id: 'aPhones' }],
    }),
    setProfilePrimaryAPhone: build.mutation<AssociationPhone, EditSecondaryPhoneRequest>({
      query: ({ body }) => ({ url: `${path}/a/phones/primary`, method: 'PATCH', body }),
      invalidatesTags: [type],
    }),

    getProfileAEmails: build.query<AssociationEmail[], number>({
      query: () => ({ url: `${path}/a/emails` }),
      providesTags: [{ type, id: 'aEmails' }],
    }),
    claimProfileAEmail: build.mutation<AssociationEmail, AddEmailRequest>({
      query: ({ body }) => ({ url: `${path}/a/emails/claim`, method: 'PUT', body }),
    }),
    abandonProfileAEmail: build.mutation<AssociationEmail, EditEmailRequest>({
      query: ({ body }) => ({ url: `${path}/a/emails/abandon`, method: 'DELETE', body }),
      invalidatesTags: [{ type, id: 'aEmails' }],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };

export const {
  useViewCurrentUserProfileQuery,
  useLazyViewCurrentUserProfileQuery,
  useChangeProfilePasswordMutation,
  useUpdateProfilePreferencesMutation,
  useSetProfileTimezoneMutation,

  useGetProfileAEmailsQuery,
  useClaimProfileAEmailMutation,
  useAbandonProfileAEmailMutation,

  useGetProfileAPhonesQuery,
  useClaimProfileAPhoneMutation,
  useAbandonProfileAPhoneMutation,
  useSetProfilePrimaryAPhoneMutation,
} = injectedRtkApi;
