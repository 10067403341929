import { FA } from '@/components/common/icons/FA';
import { FontAwesomeIcon, FontAwesomeThemes } from '@/types/common/fa';
import { Button as AntButton } from 'antd';
import { ButtonSize } from 'antd/es/button';
import { NativeButtonProps } from 'antd/lib/button/button';
import cn from 'classnames';

// Styles
import s from './Button.module.less';

export interface ButtonProps extends Omit<Partial<NativeButtonProps>, 'id'> {
  icon?: FontAwesomeIcon;
  theme?: FontAwesomeThemes;
  className?: string;
  modification?: ButtonModifications;
  circle?: boolean;
  size?: ButtonSize;
}

// Button -> synced with Button.module.less
export type ButtonModifications =
  // Roles
  | 'writer'
  | 'writerOutlined'
  | 'customer'
  | 'customerOutlined'
  // Status
  | 'unable'
  | 'unableOutlined'
  // Colors
  | 'green'
  | 'greenOutlined'
  | 'purple'
  | 'purpleOutlined'
  | 'orange'
  | 'orangeOutlined'
  | 'blue'
  | 'blueOutlined'
  | 'red'
  | 'redOutlined'
  | 'primary'
  | 'primaryOutlined'
  | 'cyan'
  | 'cyanOutlined'
  | 'lightBlue'
  | 'black'
  | 'blackOutlined';

export const Button: FC<ButtonProps> = ({ icon, children, loading, theme, modification, className, circle, color, ...props }) => (
  <AntButton
    loading={!icon && loading} // only if no icon
    icon={typeof icon === 'object' && icon}
    shape={circle ? 'circle' : 'default'}
    className={cn(className, children ? s.hasChildren : s.iconOnly, {
      [s[`${props.size}`]]: props.size,
      [s[`${modification}`]]: !!modification,
      [s.custom]: !!modification,
      [s.loading]: !!loading, // loading mode
    })}
    {...props} // supports all antd button props
  >
    {/* Prefix */}
    {icon && <FA style={{ color: modification ? 'inherit' : color }} icon={loading ? 'fa-spinner-third' : icon} theme={theme || 'far'} />}
    {/* Wrapped content */}
    {!circle && children}
  </AntButton>
);
