import { MSErrorResponse } from '@/addons/mailService/types/common';
import { InfoTooltip } from '@/components/common/InfoTooltip';
import { API_BASE_URL } from '@/config/constants';
import { FEATURE_LIST, FEATURES, FeatureType } from '@/config/features';
import { useAppDispatch } from '@/hooks/redux';
import { notification } from '@/shared/features/notification/notification';
import { msg } from '@/shared/helpers/msg';
import { IErrorResponse } from '@/shared/types/api';
import { IAccessToken } from '@/shared/types/auth';
import { authLogout } from '@/store/auth/authActions';
import { setMaintenance } from '@/store/global/globalSlice';
import { getErrorText, getNormalizedArgs } from '@/store/helpers';
import { RootState } from '@/store/store';
import { ApiEndpointExtraOptions } from '@/types/common/api';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import { refreshAccessToken } from './auth/authSlice';

let refreshTokenPromise: Promise<boolean> | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth.tokens;
    accessToken && headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: ApiEndpointExtraOptions,
) => {
  extraOptions?.log && console.log('[baseQueryWithReauth] log', { args, api, extraOptions });

  args = getNormalizedArgs(args);

  const initialAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  // Original request
  const result = await baseQuery(args, api, extraOptions);

  // if request is successful do nothing
  if (!result.error) return result;

  const { status } = result.error;

  // Maintenance splashscreen: Dashboard|Login
  if (status === 503) {
    api.dispatch(setMaintenance(true)); // wait for page reload
    return result; // skip other checks & error notification
  }

  const currentAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  // Access token expired
  if (status === 401) {
    const { refreshToken } = (api.getState() as RootState).auth.tokens;

    // if no refresh token logout and return error
    if (!refreshToken) {
      await api.dispatch(authLogout());
      return result;
    }

    const isRefreshRequestRequired =
      !refreshTokenPromise && (currentAccessToken === initialAccessToken || currentAccessToken !== refreshToken);

    if (isRefreshRequestRequired) {
      api.dispatch(refreshAccessToken({ accessToken: refreshToken }));

      refreshTokenPromise = (async () => {
        try {
          // Swap tokens before refresh
          const result = await baseQuery({ url: '/auth/refresh', method: 'POST' }, api, extraOptions);

          const { accessToken } = (await result.data) as IAccessToken;

          api.dispatch(refreshAccessToken({ accessToken }));
          refreshTokenPromise = null;
          return true;
        } catch (err) {
          api.dispatch(authLogout()); // refresh access token failed!
          return false;
        }
      })();
    }

    const refreshResult = refreshTokenPromise ? await refreshTokenPromise : false;

    if (refreshResult) {
      return await baseQuery(args, api, extraOptions);
    }
  }

  // Forbidden or tokens revoked
  if (status === 403) {
    if (api.endpoint === 'viewCurrentUserProfile') {
      await api.dispatch(authLogout());
    }
    msg.error('Action not allowed');

    // During debug roles...
    try {
      const errorMessage = JSON.parse(JSON.stringify(result.error?.data)).error;
      errorMessage && console.error(result.meta?.request.method, result.meta?.request.url, errorMessage?.replace('`', ''));
    } catch (e) {
      console.error(result.error?.data);
    }
    return result;
  }

  /**
   * Global error handler
   * Use extraOptions -> customErrorHandler for disable this handler
   *     endpointName: build.mutation<R,A>({
   *        ...
   *       extraOptions: { customErrorHandler: true }, // <- skip global error handler
   *     }),
   */

  const data = (result?.error as IErrorResponse & MSErrorResponse)?.data;

  if (!extraOptions?.customErrorHandler) {
    // Not found
    if (status === 404) return result;

    const message: string = data?.error?.replaceAll(/`/g, '').substring(0, 200) || data?.detail || 'Server error';

    const errorText = getErrorText(args, result?.error as IErrorResponse & MSErrorResponse);

    notification.error({
      message: (
        <>
          {typeof message === 'object' ? <pre>{JSON.stringify(message, null, 2)}</pre> : message}
          <InfoTooltip text={errorText} />
        </>
      ),
    }); // notification instead of msg -> coz: large text
  }

  console.error('❗️ API', JSON.stringify(result, null, 2)); // always show console.error

  return result;
};

/*
 * Define a service using app URL and expected endpoints
 * Enhance generated endpoints with tags: providesTags & invalidatesTags
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#tags
 * More at: https://www.graphql-code-generator.com/plugins/typescript-rtk-query
 */

export const emptyApi = createApi({
  reducerPath: '_api',
  tagTypes: FEATURE_LIST,
  refetchOnReconnect: true, // test it
  refetchOnFocus: true, // test it
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

/*
 * Same tags for all entities!
 * Works great!
 */
export const getTags = (type: FeatureType, dependentTypes?: FeatureType[]) => ({
  // Mutations
  invalidatesTags: (result, error, args) => {
    const tags: { type: FeatureType; id?: string }[] = [
      { type }, // main (feature has been updated -> mark data as outdated
    ];

    // Single view
    const id = typeof args === 'number' ? args : args?.id;
    if (id && Number(id)) tags.push({ type, id: `${id}` });

    // single view of dependentTypes
    dependentTypes?.forEach((type) => tags.push({ type }));

    return tags;
  },
  // Queries
  providesTags: (result, error, id) => [{ type, id }], // for single view only
  // Search view ->  providesTags: () => [type, { type, id: 'search' }],
});

type InvalidateSingleTagParams = {
  type: FeatureType;
  container?: string | number;
  id?: string | number;
};

type InvalidateSingleTuple = [type: FeatureType, container?: string | number, id?: string | number];

type BulkInvalidateTagsParams = (InvalidateSingleTagParams | FeatureType | InvalidateSingleTuple)[] | FeatureType;

export const useInvalidateTags = (tags: BulkInvalidateTagsParams, notify?: boolean) => {
  const dispatch = useAppDispatch();

  return (withMessage?: unknown) => {
    dispatch(
      emptyApi.util.invalidateTags(
        typeof tags === 'string'
          ? [
              {
                type: tags,
              },
            ]
          : tags.map((tag) => {
              if (typeof tag === 'string') return { type: tag };

              if (Array.isArray(tag)) {
                const id = `${tag[1] || ''}${tag[2] || ''}`;
                return {
                  type: tag[0],
                  id,
                };
              }

              const obj: {
                type: FeatureType;
                id?: string;
              } = {
                type: tag.type,
              };

              if (tag.container || tag.id) {
                obj.id = `${tag.container || ''}${tag.id || ''}`;
              }

              return obj;
            }),
      ),
    );

    const sendNotification = typeof withMessage === 'boolean' ? withMessage : notify;

    sendNotification && msg.success('All data updated'); // UX! prevents reload the app
  };
};

export const FILE_DEPENDENT_TAGS: FeatureType[] = [
  'Revision',
  'Grading',
  'Ticket',
  'User',
  'Customer',
  'Writer',
  'WriterPass',
  'WriterOrder',
  'TestEssay',
  'TestQuiz',
  'TestEssayUndertaking',
  'TestQuizUndertaking',
];

export const getFeaturePath = (type: FeatureType) => {
  const { apiPath, path } = FEATURES[type];

  return apiPath || path;
};
