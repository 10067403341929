import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGlobalSlice {
  isMaintenance: boolean;
}

export const initialState: IGlobalSlice = {
  isMaintenance: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setMaintenance(state: IGlobalSlice, { payload }: PayloadAction<boolean>) {
      state.isMaintenance = payload;
    },
  },
});

export const { setMaintenance } = globalSlice.actions;

export const isMaintenanceSelector = ({ global }: RootState): boolean => global.isMaintenance;

export default globalSlice.reducer;
