import { upperFirst } from 'lodash';

export const getCSSVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);
/**
 * Truncate string & append … in the end
 * @param str input string
 * @param max maximum length
 */
export const truncateString = (str: string, max: number): string => (str.length > max ? `${str.substr(0, max - 1)}…` : str);
/**
 * Remove all html tags from str
 * @param str input string
 */
export const stripTags = (str?: string): string => str?.replace(/(<([^>]+)>)/gi, '') || '';
export const normalCase = (str: string) => upperFirst(str.toLowerCase());
export const replaceTemplate = (str: string, s: string) => (str.match('%s') ? str.replace('%s', s) : str);
