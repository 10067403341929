import { IAccessToken, ITokenPair } from '@/shared/types/auth';
import { AuthLoginRequest } from '@/types/auth';
import { emptyApi as api } from '@/store/emptyApi';
import { setTokens } from '@/store/auth/authSlice';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authLogin: build.mutation<ITokenPair, AuthLoginRequest>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(setTokens(data));
      },
      extraOptions: { customErrorHandler: true }, // skip global error handler
    }),
    authRefresh: build.mutation<IAccessToken, void>({
      query: () => ({
        url: '/auth/refresh',
        method: 'POST',
      }),
    }),
    authLogout: build.mutation<unknown, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
    authGlobalLogout: build.mutation<unknown, void>({
      query: () => ({
        url: '/auth/global-logout',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const { useAuthLoginMutation, useAuthRefreshMutation, useAuthLogoutMutation, useAuthGlobalLogoutMutation } = injectedRtkApi;
