import { ConditionsArray } from '@/components/guards/FeatureGuard';
import { ACTIVE_HOURS_FRONT_TIME_FORMAT, SERVER_TIME_FORMAT } from '@/config/constants';
import { OrderExtended } from '@/types/order';
import { OrderSnapshot } from '@/types/orderSnapshot';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import moment, { Moment } from 'moment-timezone';
import _pluralize from 'pluralize';

export const renderRawJSON = (obj: any) => JSON.stringify(obj);

// Sync this enum with -> styles/core/_colors.less
export type AppColors =
  | 'gray'
  | 'red'
  | 'purple'
  | 'user'
  | 'customer'
  | 'writer'
  | 'primary'
  | 'background'
  | 'disabled'
  | 'stripped'
  | 'success'
  | 'danger'
  | 'warning'
  | 'error'
  | 'unable'
  | 'info'
  | 'turquoise'
  | 'muted'
  | 'muted-text'
  | 'draft'
  | 'paused'
  | 'active'
  | 'resolved'
  | 'pending'
  | 'inwork'
  | 'removed'
  | 'aborted'
  | 'declined'
  | 'available'
  | 'border'
  | 'link'
  | 'text'
  | 'heading';

export const getColor = (color: AppColors) => getComputedStyle(document.documentElement).getPropertyValue(`--${color}-color`);

/**
 * Get the shortest deadline with time shift
 * @param deadlineInHours
 * @returns Moment object
 */
export const getShortestDeadline = (deadlineInHours: number): Moment => moment().add(deadlineInHours, 'hours'); // now + selected deadline in hours

/**
 * Get the shortest deadline with time shift
 * @param deadlineInHours
 * @param format e.g. 'MM.DD.YY HH:mm:ss'
 * @returns Formatted string
 */
export const getShortestDeadlineFormatted = (deadlineInHours: number, format?: string): string =>
  getShortestDeadline(deadlineInHours).format(format || 'MM.DD.YY HH:mm');

export const getFirstFromList = (list): number | undefined => (list?.length ? list[0]?.id : undefined);

/**
 * Filter array by condition key
 */
export const filterByConditionFn = ({ condition }: { condition?: boolean }) => condition; // get available

export const activeHoursTimeFormatBackendToFrontend = (time: string) =>
  moment(time, SERVER_TIME_FORMAT).format(ACTIVE_HOURS_FRONT_TIME_FORMAT);

export const getActiveHoursValue = (start?: string, end?: string) =>
  start && end ? `${activeHoursTimeFormatBackendToFrontend(start)} - ${activeHoursTimeFormatBackendToFrontend(end)}` : undefined;

// Hm! I'm not sure... ((
export const getOrderSnapshot = (order: OrderExtended | undefined): OrderSnapshot | null =>
  order ? order?.snapshot || order?.sandboxSnapshot : null;

// Get ConditionsArray as argument & returns merged condition as boolean
export const mergeConditions = (conditions: ConditionsArray | undefined): boolean =>
  Boolean(conditions && conditions.reduce((res, { condition }) => res && !!condition, true));

// Get error code from FetchBaseQueryError
export const getErrorCode = (error: FetchBaseQueryError | SerializedError | undefined): number => {
  if (!error) return 0; // unknown
  if ('originalStatus' in error) return error.originalStatus; // main
  if ('status' in error) return +error.status || 0; // fallback
  return 0; // default
};

export const getEntityDisplayName = (obj: { id?: number; name?: string; customerName?: string; writerName?: string }) => {
  const { name, customerName, writerName } = {
    name: obj?.name?.trim(),
    customerName: obj?.customerName?.trim(),
    writerName: obj?.writerName?.trim(),
  };

  return name || customerName || writerName || (obj.id ? `ID: ${obj.id}` : 'Untitled');
};

export const getEntityDescription = (obj: {
  id?: number;
  description?: string;
  customerDescription?: string;
  writerDescription?: string;
}) => {
  const { description, customerDescription, writerDescription } = {
    description: obj?.description?.trim(),
    customerDescription: obj?.customerDescription?.trim(),
    writerDescription: obj?.writerDescription?.trim(),
  };

  return description || customerDescription || writerDescription;
};
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

/*
 * Pluralize any word (TODO: is it useful?)
 * Docs: https://github.com/plurals/pluralize
 */
_pluralize.addPluralRule(/quiz$/i, 'quizzes');
export const pluralize = (word: string | undefined = '', count?: number, inclusive?: boolean): string => _pluralize(word, count, inclusive);

// Returns -> 2h 03m 30s

// eslint-disable-next-line flowtype/require-valid-file-annotation
// TODO: @floatrx duplicates @/helpers/time secondsToStrTime
export const formatDuration = (durationInSeconds: number | undefined, format?: 'simple' | 'standard'): string => {
  if (!durationInSeconds) return 'n\\a';

  // Hours, minutes and seconds
  const hh = ~~(durationInSeconds / 3600);
  const mm = ~~((durationInSeconds % 3600) / 60);
  const ss = ~~durationInSeconds % 60;

  let res = '';

  // Format? TODO: Check this
  if (format) console.log('[!] Refactor me');
  if (hh) res += `${hh}h `;
  if (mm) res += `${mm < 10 && hh ? '0' : ''}${mm}m `;
  if (ss) res += `${ss < 10 ? '0' : ''}${ss}s`;

  return res;
};

export const getTruncatedDisplayName = (name: string, truncate?: number): string => {
  if (!truncate || name.length <= truncate) {
    return name;
  }

  return `${name.slice(0, truncate - 3)}...`;
};

export const getGrade = (value: number) =>
  ({
    12: 'A+',
    11: 'A',
    10: 'A-',
    9: 'B+',
    8: 'B',
    7: 'B-',
    6: 'C+',
    5: 'C',
    4: 'C-',
    3: 'D+',
    2: 'D',
    1: 'D-',
    0: 'F',
  }[String(value)]);

export const getDateExpiredFormat = (date: string) => {
  if (moment.utc(date).diff(moment.utc(), 'year') < 0) {
    return 'y[y] M[m] w[w] ago';
  } else if (moment.utc(date).diff(moment.utc(), 'month') < 0) {
    return 'M[m] w[w] d[d] ago';
  } else if (moment.utc(date).diff(moment.utc(), 'week') < 0) {
    return 'w[w] d[d] h[h] ago';
  } else if (moment.utc(date).diff(moment.utc(), 'day') < 0) {
    return 'd[d] h[h] m[m] ago';
  }
  return 'h[h] m[m] s[s] ago';
};

export const getDateDefaultFormat = (date: string) => {
  if (moment.utc(date).diff(moment.utc(), 'year') > 0) {
    return 'y[y] M[m] w[w] ago';
  } else if (moment.utc(date).diff(moment.utc(), 'month') > 0) {
    return 'M[m] w[w] d[d]';
  } else if (moment.utc(date).diff(moment.utc(), 'week') > 0) {
    return 'w[w] d[d] h[h]';
  } else if (moment.utc(date).diff(moment.utc(), 'day') > 0) {
    return 'd[d] h[h] m[m]';
  }
  return 'h[h] m[m] s[s]';
};
