import { Roles } from '@/types/user';
import { FeatureItem } from '@/config/features';
import { SCHEDULER_API_URL, SCHEDULER_PATH_PREFIX } from '@/addons/scheduler/config/constants';

export const S_FEATURE_LIST = ['SCountdownTask', 'SIntervalTask'] as const;

export type SFeatureType = typeof S_FEATURE_LIST[number];

type SFeaturesDictionary = {
  [key in SFeatureType]: FeatureItem;
};

export const S_FEATURES: SFeaturesDictionary = {
  // Mail service
  SCountdownTask: {
    type: 'SCountdownTask',
    title: 'Countdown tasks',
    path: `${SCHEDULER_PATH_PREFIX}/countdown-tasks`,
    apiPath: `${SCHEDULER_API_URL}/countdowns`,
    category: 'hidden',
    icon: 'fa-list-check',
    roles: [Roles.ADMIN],
  },
  SIntervalTask: {
    type: 'SIntervalTask',
    title: 'Interval tasks',
    path: `${SCHEDULER_PATH_PREFIX}/interval-tasks`,
    apiPath: `${SCHEDULER_API_URL}/intervals`,
    category: 'hidden',
    icon: 'fa-list-check',
    roles: [Roles.ADMIN],
  },
};
