import { useWindowFocus } from '@/shared/hooks/useWindowFocused';
import { useEffect, useRef, useState } from 'react';

const CHECK_APP_VERSION_INTERVAL = 60000; // every one minute
export const useAppVersion = () => {
  const [reloadRequired, setReloadRequired] = useState(false);

  const appVersion = useRef<string>();

  // Refetch
  const isFocused = useWindowFocus(); // skip if tab is inactive
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    console.log('App checker inited');
    const checkAppVersion = async () => {
      if (!isFocused) return console.log('checkAppVersion > Skipped (tab inactive)');

      try {
        const result = await fetch('/manifest.json', {
          headers: {
            pragma: 'no-cache', // eslint-disable-line quote-props
            'cache-control': 'no-cache',
          },
        });
        const { version_name: manifestVersion } = await result.json();
        if (!appVersion.current) {
          appVersion.current = manifestVersion;
        } else if (manifestVersion !== appVersion.current) {
          setReloadRequired(true);
          return;
        }
      } catch (err) {}
    };
    timerRef.current = setInterval(checkAppVersion, CHECK_APP_VERSION_INTERVAL);
    return () => clearInterval(timerRef.current);
  }, [isFocused]);

  return {
    reloadRequired,
  };
};
