import { SERVER_TIME_FORMAT } from '@/config/constants';
import moment, { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { usePreferences } from '@/hooks/preferences';

type UseClockReturnType = {
  dateTime: Moment;
  formattedTime: string;
  timezone: string;
};

type UseClockOptions = { updateInterval?: number; timezone?: string };

export const useClock = ({ updateInterval, timezone }: UseClockOptions): UseClockReturnType => {
  const { timezone: prefTimezone } = usePreferences();

  const targetTimezone = timezone || prefTimezone;

  const [dateTime, setDateTime] = useState<Moment>(targetTimezone ? moment().tz(targetTimezone) : moment());

  const formattedTime: string = dateTime.format(SERVER_TIME_FORMAT);

  // Clock
  useEffect(() => {
    const intervalId = setInterval(() => setDateTime(targetTimezone ? moment().tz(targetTimezone) : moment()), updateInterval || 1000);
    return () => clearInterval(intervalId);
  }, [updateInterval, targetTimezone]);

  useEffect(() => {
    setDateTime(targetTimezone ? moment().tz(targetTimezone) : moment());
  }, [targetTimezone]);

  return {
    dateTime,
    timezone: targetTimezone,
    formattedTime, // 'HH:mm:ss'
  };
};

// Sync differences between timezone names on back-end and front-end
export const getTimezoneName = (value: string) =>
  ({
    'Europe/Kiev': 'Europe/Kyiv',
  }[value] || value);
