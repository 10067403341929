import { Spin } from 'antd';

import s from './SpinFullscreen.module.less';

interface IProps {
  tip?: string;
}

export const SpinFullscreen: FC<IProps> = ({ tip }) => (
  <Spin
    size="large"
    tip={tip}
    className={s.spinner}
    delay={100}
    style={{
      position: 'absolute',
      minHeight: 'calc(100vh - var(--header-height,60px)',
      display: 'flex',
    }}
  />
);
