import { Roles } from '@/types/user';

/**
 * ROLE GROUPS (cascades).
 * Each next group extends the previous one
 */
export const RG_SUPER_ADMIN = [Roles.ADMIN];
export const RG_ADMINS = [Roles.ADMIN, Roles.SEMI_ADMIN];
export const RG_QA_TEAM_LEAD = [...RG_ADMINS, Roles.QA_TEAM_LEAD];
export const RG_QA_AGENT = [...RG_QA_TEAM_LEAD, Roles.QA_AGENT];
export const RG_S_TEAM_LEAD = [...RG_ADMINS, Roles.S_TEAM_LEAD];
export const RG_S_SENIOR = [...RG_S_TEAM_LEAD, Roles.S_SENIOR, ...RG_QA_AGENT];
export const RG_S_MIDDLE = [...RG_S_SENIOR, Roles.S_MIDDLE];
export const RG_S_JUNIOR = [...RG_S_MIDDLE, Roles.S_JUNIOR];
export const RG_S_TRAINEE = [...RG_S_JUNIOR, Roles.S_TRAINEE];
