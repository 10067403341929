// App
import App from '@/components/App';
import { suppressAntdWarnings } from '@/shared/helpers/errors';
import { initSentry } from '@/shared/helpers/sentry';
import { persistor, store } from '@/store/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './styles';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

// Errors
suppressAntdWarnings();

// Handle all exceptions with Sentry
initSentry();
