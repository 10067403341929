import { useCallback, useEffect, useState } from 'react';
import { TWindowBreakpoint } from '@/types/breakpoints';
import { usePreferences } from '@/hooks/preferences';
import { getCSSVar } from '@/shared/helpers/utils';
import { throttle } from 'lodash';

export const useTrackBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<TWindowBreakpoint>('xxl');

  const { preferences } = usePreferences();

  /*
   * Adaptive dependent on controls with
   * When sidebar is active or main navigation is expanded - trigger breakpoint earlier
   */
  const { sidebarActiveKey, isCompactNavigation } = preferences;
  const controlsWidth = (isCompactNavigation ? 50 : 200) + (sidebarActiveKey ? 300 : 0);

  const watchBreakPoint = useCallback(() => {
    const breakPoints: [TWindowBreakpoint, string][] = [
      ['xxs', getCSSVar('--xxs')],
      ['xs', getCSSVar('--xs')],
      ['sm', getCSSVar('--sm')],
      ['md', getCSSVar('--md')],
      ['lg', getCSSVar('--lg')],
      ['xl', getCSSVar('--xl')],
      ['xxl', getCSSVar('--xxl')],
    ];

    const targetBreakPoint = breakPoints.find(
      ([_, size]) => window.matchMedia(`only screen and (max-width: ${+size + controlsWidth}px)`).matches,
    );

    setBreakPoint(targetBreakPoint?.[0] || 'xxxl');
  }, [controlsWidth]);

  useEffect(() => {
    watchBreakPoint();

    const debouncedWatchBreakPoint = throttle(watchBreakPoint, 250); // 350 is magic number
    window.addEventListener('resize', debouncedWatchBreakPoint);

    return () => {
      window.removeEventListener('resize', debouncedWatchBreakPoint);
    };
  }, [watchBreakPoint]);

  return breakpoint;
};
