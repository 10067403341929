import { DateTime } from '@/components/common/DateTime';
import { CustomerIcon } from '@/components/common/icons/CustomerIcon';
import { FA } from '@/components/common/icons/FA';
import { UserIcon } from '@/components/common/icons/UserIcon';
import { WriterIcon } from '@/components/common/icons/WriterIcon';
import { TIME_FORMAT } from '@/config/constants';
import { getColor } from '@/helpers/utils';
import { CommonMessageStatuses, CommonMessageVisibilities, MessengerConfig } from '@/shared/features/messenger/types/messages';
import moment from 'moment/moment';

export const MESSENGER_CONFIG: MessengerConfig = {
  websockets: {
    ticket: 'users/messages',
  },
  icons: {
    disconnected: <FA icon="fa-chain-broken" text="Disconnected" />,
    customer: <CustomerIcon />,
    writer: <WriterIcon />,
    user: <UserIcon />,
    messages: <FA icon="fa-messages" theme="far" />,
    quote: <FA icon="fa-messages" theme="fad" color={getColor('primary')} />,
    spinner: <FA icon="fa-spinner-third" className="fa-spin" />,
    reply: <FA icon="fa-reply" theme="far" color={getColor('primary')} />,
    actions: <FA icon="fa-ellipsis-v" theme="far" />,
    cancel: <FA icon="fa-times" />,
    edit: <FA icon="fa-edit" theme="far" color={getColor('primary')} />,
    unknown: '🤨',
    [CommonMessageStatuses.Posted]: <FA icon="fa-check" />,
    [CommonMessageStatuses.Removed]: <FA icon="fa-trash" />,
    [CommonMessageStatuses.Retracted]: <FA icon="fa-times" />,
    [CommonMessageStatuses.Unchecked]: <FA icon="fa-exclamation-circle" />,
    [CommonMessageStatuses.Declined]: <FA icon="fa-times" />,
  },

  colors: {
    primary: getColor('muted'),
    customer: getColor('muted'),
    writer: getColor('muted'),
    support: getColor('muted'),
    muted: getColor('text'),
  },

  channels: {
    customer: {
      label: '#customer',
      idVisibility: CommonMessageVisibilities.Customer,
      direction: 'Support & Customer',
    },
    writer: {
      label: '#writer',
      idVisibility: CommonMessageVisibilities.Writer,
      direction: 'Support & Writer',
    },
    public: {
      label: '#public',
      idVisibility: CommonMessageVisibilities.Everyone,
      direction: 'Writer & Customer',
    },
    private: {
      label: '#private',
      idVisibility: CommonMessageVisibilities.User,
      direction: 'Teammates',
    },
  },
  defineStatusIconVisibility: (hoverActionsAvailable: boolean, idStatus: CommonMessageStatuses) =>
    hoverActionsAvailable ? ![CommonMessageStatuses.Unchecked, CommonMessageStatuses.Posted].includes(idStatus) : true,

  renderMessage: {
    date: ({ dateAdded }) => {
      const now = moment();
      const fromNow = now.diff(dateAdded, 'hour') < 1; // group all messages diff 30seconds
      const isSameDay = now.diff(dateAdded, 'day') <= 1;
      return <DateTime value={dateAdded} fromNow={fromNow} customFormat={isSameDay ? TIME_FORMAT : `DD MMM ${TIME_FORMAT}`} />;
    },
  },
};
